import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Section from '../components/shared/Section'
import Container from '../components/shared/Container'
import PageTitle from '../components/shared/PageTitle'
import SEO from '../components/Seo/Seo'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <>
      <Section>
        {helmet || ''}
        <Container>
          <PageTitle>{title}</PageTitle>
          <PostContent content={content} />
        </Container>
      </Section>
    </>
  )
}

const BlogPost = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { title, date, description },
    },
  } = data

  return (
    <Layout>
      <SEO title={title} description={description} />
      <BlogPostTemplate
        content={html}
        contentComponent={HTMLContent}
        title={title}
        date={date}
      />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(quality: 50, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
