import React from 'react'
import PropTypes from 'prop-types'
import { StyledSection } from './styles'

const Section = ({ children, dark }) => (
  <StyledSection dark={dark}>{children}</StyledSection>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
