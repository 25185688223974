import styled from 'styled-components'

export const StyledSection = styled.div`
  padding: 2rem 0;
  background-color: ${({ dark }) => (dark ? 'var(--gray1)' : 'transparent')};

  @media (min-width: 768px) {
    padding: 3.75rem 0;
  }
`
